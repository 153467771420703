import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import app from "./app";
import users from "./users";
import analytics from "./analytics";
import requests from "./requests";

export default (history) => combineReducers({
  router: connectRouter(history),
  app,
  users,
  analytics,
  requests,
})

import * as actions from "../global/actions";

const initState = {
  analytics: [],
};

export default function(state = initState, action = {}) {
  switch (action.type) {
    case actions.FETCH_ANALYTICS_SUCCESS:
      return {
        ...state,
        analytics: action.response.data || [],
      };

    default:
      return state;
  }
}

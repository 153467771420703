import React, { Component } from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadRequestsPage } from "../actions/requests";

import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEye, faCalendar, faFastBackward, faForward} from "@fortawesome/free-solid-svg-icons";

import * as api from "../global/api";
import Layout from "../components/Layout";
import Pagination from "../components/Pagination";
import SearchInput from "../components/SearchInput";
import appConfig from "../config/application";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Body = styled.div`
`;

const dateToString = (today) => {
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
}

const sortByOptions = [
  { value: "createdAt", text: "Created At" },
  { value: "type", text: "Type" },
];

const DateInput = React.forwardRef(({ hint, value, onClick }, ref) => {
  return <div className="tw-flex tw-flex-1 tw-flex-row tw-mr-4" style={{ height: 32 }}>
    <input
        placeholder={hint}
        onClick={onClick}
        type="text"
        className="tw-flex-1 tw-text-xs tw-text-black tw-py tw-pl-3 tw-rounded tw-rounded-r-none tw-border tw-border-solid tw-border-grey-light tw-border-r-0 tw-w-32 focus:tw-outline-none"
        value={value}
        readOnly
    />
    <span className="tw-flex tw-items-center tw-rounded tw-rounded-l-none tw-px-3 tw-text-grey-light tw-border tw-border-solid tw-border-grey-light tw-border-l-0">
        <FontAwesomeIcon icon={faCalendar} />
      </span>
  </div>;
});

class UserRequestsPage extends Component {
  constructor(props) {
    super(props);

    const filters = {
      ...this.props.requests.filters,
      userId: this.props.match.params.id,
    }

    const domain = window.location.hostname;
    const isProduct = domain.includes('afi');
    this.state = {
      filters,
      showInfo: false,
      request: null,
      demo: !isProduct,
      startDate: null,
      endDate: null,
    };
  }

  componentDidMount() {
    const { filters } = this.state;
    this.reloadPage(filters);
  }

  handleMovePage = data => {
    const page = data.selected + 1;
    const filters = {
      ...this.state.filters,
      page
    };
    this.setState({...this.state, filters});
    this.reloadPage(filters);
  };

  handleNextPage = () => {
    const page = (this.state.filters.page || 1) + 1;
    const filters = {
      ...this.state.filters,
      page
    };
    this.setState({...this.state, filters});
    this.reloadPage(filters);
  };

  handleFirstPage = () => {
    const page = 1;
    const filters = {
      ...this.state.filters,
      page
    };
    this.setState({...this.state, filters});
    this.reloadPage(filters);
  };

  onChangeSearch = (value) => {
    const filters = {
      ...this.state.filters,
      search: value,
      page: 1,
    };
    this.setState({...this.state, filters});
    this.reloadPage(filters);
  }

  onClearSearch = () => {
    const filters = {
      limit: 20,
      page: 1,
      search: "",
      orderBy: "createdAt",
    };
    this.setState({
      ...this.state, filters,
      startDate: null, endDate: null
    }, () => {
      this.reloadPage(filters);
    });
  }

  onSort = (event) => {
    const filters = {
      ...this.state.filters,
      orderBy: event.target.value,
      page: 1,
    };
    this.setState({...this.state, filters});
    this.reloadPage(filters);
  }

  reloadPage = (filters) => {
    this.props.loadRequestsPage({
      ...filters,
      startDate: this.state.startDate ? dateToString(this.state.startDate) : this.state.startDate,
      endDate: this.state.endDate ? dateToString(this.state.endDate) : this.state.endDate,
    });
  }

  handleShowRequest = (newRequest) => {
    const { request } = this.state;

    if (request === null || request.id !== newRequest.id) {
      api.fetchRequest(newRequest.id).then(({ response, error }) => {
        if (error) {
          alert(error);
        } else {
          this.setState({ showInfo: true, request: response.data });
        }
      });
    } else {
      this.setState({ showInfo: true, request: request });
    }
  }

  handleCloseRequest = () => {
    this.setState({ showInfo: false });
  }

  setStartDate = (date) => {
    this.setState({ ...this.state, startDate: date });
  }

  setEndDate = (date) => {
    this.setState({ ...this.state, endDate: date });
  }

  render() {
    const { filters, request, startDate, endDate } = this.state;
    const { requests } = this.props;
    const urlView = appConfig.viewerURI + '/jobs';
    const mapboxUrlView = 'https://viewer.afi.io/jobs';

    return (
        <Layout>
          <Body>
            <Modal size="lg" show={this.state.showInfo} onHide={this.handleCloseRequest}>
              <Modal.Body>
                <div className="header">Request</div>
                {request &&
                    <table className="tw-text-xs table table-striped table-sm">
                      <tbody>
                      <tr>
                        <td>Job Id</td>
                        <td>{request.jobId}</td>
                      </tr>
                      <tr>
                        <td>Type</td>
                        <td>{request.type}</td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td>{request.status}</td>
                      </tr>
                      <tr>
                        <td>Visits</td>
                        <td>{request.type === 'PDP' ? request.totalVisits * 2 : request.totalVisits}</td>
                      </tr>
                      <tr>
                        <td>Vehicles</td>
                        <td>{request.totalVehicles}</td>
                      </tr>
                      <tr>
                        <td>Input</td>
                        <td><textarea className="tw-w-full" rows="5" defaultValue={JSON.stringify(JSON.parse(request.jsonInput), undefined, 4)} /></td>
                      </tr>
                      <tr>
                        <td>Unserved</td>
                        <td>{request.numUnserved}</td>
                      </tr>
                      {(request.numUnserved > 0 && request.status === 'finished') && <tr>
                        <td></td>
                        <td><textarea className="tw-w-full" rows="5" defaultValue={JSON.stringify((JSON.parse(request.jsonView))['output']['unserved'], undefined, 4)} /></td>
                      </tr>}
                      <tr>
                        <td>Idle Time</td>
                        <td>{request.totalIdleTime}</td>
                      </tr>
                      <tr>
                        <td>Break Time</td>
                        <td>{request.totalBreakTime}</td>
                      </tr>
                      <tr>
                        <td>Travel Time</td>
                        <td>{request.totalTravelTime}</td>
                      </tr>
                      <tr>
                        <td>Solution</td>
                        <td><textarea className="tw-w-full" rows="5" defaultValue={JSON.stringify(JSON.parse(request.jsonSolution), undefined, 4)} /></td>
                      </tr>
                      <tr>
                        <td>Processing time</td>
                        <td>
                          {request.status === 'finished' && <span>{moment.duration(moment(request.updatedAt).diff(moment(request.createdAt))).asMinutes().toFixed(3)} minutes</span>}
                          {request.status !== 'finished' && <span>0</span>}
                        </td>
                      </tr>
                      <tr>
                        <td>Created Date</td>
                        <td>{moment(request.createdAt).format("LLL")}</td>
                      </tr>
                      </tbody>
                    </table>
                }
              </Modal.Body>
            </Modal>

            <div className="tw-flex tw-justify-between tw-mb-4">
              <div className="tw-text-xl tw-font-medium">Requests</div>
            </div>

            <div className="tw-rounded tw-bg-white tw-shadow tw-mb-4">
              <div className="tw-p-4">
                <div className="tw-flex tw-flex-row tw-justify-between">
                  <div className="tw-flex">
                    <SearchInput
                        placeholder="Search by Job Id, Type"
                        value={this.state.filters.search}
                        onChange={this.onChangeSearch}
                    />

                    <DatePicker selected={startDate} onChange={date => this.setStartDate(date)} customInput={<DateInput hint="Start Date" />}/>
                    <DatePicker selected={endDate} onChange={date => this.setEndDate(date)} customInput={<DateInput hint="End Date" />}/>

                    <div className="btn btn-sm btn-pink px-3 mr-2 hover:tw-text-white" onClick={() => this.onChangeSearch()}>Search</div>
                    <div className="btn btn-sm tw-text-grey-light tw-cursor-pointer" onClick={() => this.onClearSearch()}>Clear</div>
                  </div>

                  <div className="tw-flex">
                    <div className="tw-whitespace-no-wrap tw-self-center tw-mr-2 tw-text-xs">Sort by</div>
                    <select onChange={this.onSort} className="custom-select tw-text-xs tw-py-1">
                      { sortByOptions.map((option) =>
                          <option key={option.value} value={option.value}>
                            {option.text}
                          </option>
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div>
                <div className="table-responsive tw-max-w-full">
                  <table className="table table-borderless table-striped">
                    <thead className="tw-bg-primary tw-text-white tw-text-sm">
                    <tr className="tw-uppercase">
                      <th className="tw-font-normal tw-whitespace-no-wrap">Job ID</th>
                      <th className="tw-font-normal">Type</th>
                      <th className="tw-font-normal">Status</th>
                      <th className="tw-font-normal">Visits</th>
                      <th className="tw-font-normal">Vehicles</th>
                      <th className="tw-font-normal tw-whitespace-no-wrap">Processing time</th>
                      <th className="tw-font-normal tw-whitespace-no-wrap">Created Date</th>
                      <th className="tw-font-normal"></th>
                    </tr>
                    </thead>
                    <tbody className="tw-text-xs">
                    {requests.requests.map((item, index) => {
                      let created = moment.parseZone(item.createdAt);

                      return (
                          <tr key={item.id}>
                            <td>
                              <a href={`${appConfig.apiBackendURI}/jobs/${item.jobId}`} target="_blank" rel="noopener noreferrer">{item.jobId}</a>
                            </td>
                            <td className="tw-font-semibold">{item.type}</td>
                            <td>
                              {item.status === 'pending' && <span className="tw-rounded tw-bg-orange-20 tw-text-orange tw-border-orange tw-border tw-border-solid tw-py-1 tw-px-2">Pending</span>}
                              {item.status === 'finished' && <span className="tw-rounded tw-bg-teal-20 tw-text-teal tw-border-teal tw-border tw-border-solid tw-py-1 tw-px-2">Finished</span>}
                              {item.status !== 'pending' && item.status !== 'finished' && <span className="tw-rounded tw-text-red tw-border-red tw-border tw-border-solid tw-py-1 tw-px-2">{item.status}</span>}
                            </td>
                            <td>{item.type === 'PDP' ? item.totalVisits * 2 : item.totalVisits}</td>
                            <td>{item.totalVehicles}</td>
                            <td>
                              {item.status === 'finished' && <span>{moment.duration(moment(item.updatedAt).diff(moment(item.createdAt))).asMinutes().toFixed(3)} minutes</span>}
                            </td>
                            <td>{created.format("LLL")}</td>
                            <td className="tw-text-right">
                              <FontAwesomeIcon
                                  onClick={() => this.handleShowRequest(item)}
                                  icon={faEye} className="tw-cursor-pointer tw-text-grey tw-mr-4" />

                              <a href={`${urlView}/${item.jobId}?endpoint=${appConfig.apiBackendURI}`} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon="map" className="tw-cursor-pointer tw-text-grey tw-mr-4" />
                              </a>

                              {/*<a href={`${mapboxUrlView}/${item.jobId}?endpoint=${appConfig.apiBackendURI}`} target="_blank" rel="noopener noreferrer">*/}
                              {/*  <FontAwesomeIcon icon="map" className="tw-cursor-pointer" style={{ color: '#1B2737' }}/>*/}
                              {/*</a>*/}
                            </td>
                          </tr>
                      );
                    })}
                    </tbody>
                  </table>
                  <div onClick={() => this.handleFirstPage()} className="btn btn-sm btn-red">
                    <FontAwesomeIcon icon={faFastBackward} className="tw-mr-2" /><span className="tw-text-xs">First Page</span>
                  </div>
                  <div onClick={() => this.handleNextPage()} className="btn btn-sm btn-red">
                    <FontAwesomeIcon icon={faForward} className="tw-mr-2" /><span className="tw-text-xs">Next Page</span>
                  </div>
                </div>
              </div>
            </div>
          </Body>
        </Layout>
    );
  }
}

UserRequestsPage.propTypes = {
  requests: PropTypes.object.isRequired,
  loadRequestsPage: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { requests } = state;
  return { requests };
}

export default connect(mapStateToProps, {
  loadRequestsPage,
})(UserRequestsPage);

import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactPaginate from "react-paginate";

const Body = styled.nav`
`;

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perPage: this.props.perPage,
      index: 1,
      offset: this.props.perPage,
    };
  }

  onPageChange = data => {
    const selected = data.selected;
    this.setState({
      index: selected ? Math.ceil(selected * this.state.perPage) + 1 : 1,
      offset: Math.ceil((selected+1) * this.state.perPage),
    });
    this.props.onPageChange(data);
  };

  render() {
    const pages = this.props.pages;
    const perPage = this.state.perPage;
    const index = this.state.index;
    const offset = this.state.offset;

    const info = <span>Next <span className="tw-text-xss">({index} - {offset} of {pages * perPage})</span></span>;
    return (
      <Body>
        {pages > 1 &&
          <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={info}
            breakLabel={'...'}
            pageCount={pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={this.onPageChange}
            containerClassName={'pagination justify-content-center tw-mb-6'}
            subContainerClassName={'pages pagination'}
            pageClassName={'page-item tw-cursor-pointer'}
            pageLinkClassName={'page-link tw-text-black tw-text-xs hover:tw-text-white hover:tw-bg-black'}
            previousClassName={'page-item tw-cursor-pointer'}
            nextClassName={'page-item tw-cursor-pointer'}
            previousLinkClassName={'page-link tw-text-black tw-text-xs hover:tw-text-white hover:tw-bg-black'}
            nextLinkClassName={'page-link tw-text-black tw-text-xs hover:tw-text-white hover:tw-bg-black'}
            breakClassName={'page-item tw-cursor-pointer'}
            breakLinkClassName={'page-link tw-text-black tw-text-xs hover:tw-text-white hover:tw-bg-black'}
            activeClassName={'active'}
            initialPage={this.props.page - 1 || 0}
          />
        }
      </Body>
    );
  }
}

Pagination.propTypes = {
  perPage: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;

import * as actions from "../global/actions";

function action(type, payload = {}) {
  return { type, ...payload };
}

export const loadUserLogin = (filters) => ({
  type: actions.APP_LOAD_USER_LOGIN,
  filters
});

export const userLogin = {
  request: () => action(actions.USER_LOGIN_REQUEST),
  success: (filters, response) => action(actions.USER_LOGIN_SUCCESS, { filters, response }),
  failure: (filters, error) => action(actions.USER_LOGIN_FAILURE, { filters, error }),
};

export const userLogout = () => ({
  type: actions.USER_LOGOUT_SUCCESS,
});

export const initCheckboxs = (length) => ({
  type: actions.APP_INIT_CHECKBOXS,
  length
});

export const checkCheckboxs = (checked) => ({
  type: actions.APP_CHECK_CHECKBOXS,
  checked
});

export const toogleCheckboxs = (index) => ({
  type: actions.APP_TOGGLE_CHECKBOXS,
  index
});

import { take, put, call, fork } from "redux-saga/effects";
import * as actionType from "../global/actions";
import * as action from "../actions/app";
import * as api from "../global/api";

/** *************************** Subroutines ************************************/
function* userLogin(filters) {
  yield put(action.userLogin.request(filters));

  const { response, error } = yield call(api.userLogin, filters);
  if (response) {
    yield put(action.userLogin.success(filters, response));
  } else {
    yield put(action.userLogin.failure(filters, error));
  }
}

/** ****************************************************************************/
/** ***************************** WATCHERS *************************************/
/** ****************************************************************************/
export default function* watchJob() {
  while (true) {
    const { filters } = yield take(actionType.APP_LOAD_USER_LOGIN);
    yield fork(userLogin, filters);
  }
}

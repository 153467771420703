import * as actions from "../global/actions";

function action(type, payload = {}) {
  return { type, ...payload };
}

export const fetchRequests = {
  request: () => action(actions.FETCH_REQUESTS_REQUEST),
  success: (filters, response) => action(actions.FETCH_REQUESTS_SUCCESS, { filters, response }),
  failure: (filters, error) => action(actions.FETCH_REQUESTS_FAILURE, { filters, error }),
};

export const loadRequestsPage = (filters) => ({
  type: actions.LOAD_REQUESTS_PAGE,
  filters
});

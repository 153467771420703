import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router";
import { ConnectedRouter } from "connected-react-router";
import PrivateRoute from "./containers/PrivateRoute";

import DashboardPage from "./pages/DashboardPage";
import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import UsersPage from "./pages/UsersPage";
import ProfilePage from "./pages/ProfilePage";
import RequestsPage from "./pages/RequestsPage";
import UserRequestsPage from "./pages/UserRequestsPage";

class App extends Component {
  render() {
    return (
      <ConnectedRouter history={this.props.history}>
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/logout" component={LogoutPage} />
          <Route exact path="/reset_password" component={ResetPasswordPage} />
          <PrivateRoute exact path="/" component={DashboardPage} />
          <PrivateRoute exact path="/users" component={UsersPage} />
          <PrivateRoute exact path="/profile" component={ProfilePage} />
          <PrivateRoute exact path="/requests" component={RequestsPage} />
          <PrivateRoute exact path="/requests/:id" component={UserRequestsPage} />
        </Switch>
      </ConnectedRouter>
    );
  }
}

App.propTypes = {
  history: PropTypes.object.isRequired,
};


export default App;

import * as actions from "../global/actions";

function action(type, payload = {}) {
  return { type, ...payload };
}

export const fetchAnalytics = {
  request: () => action(actions.FETCH_ANALYTICS_REQUEST),
  success: (filters, response) => action(actions.FETCH_ANALYTICS_SUCCESS, { filters, response }),
  failure: (filters, error) => action(actions.FETCH_ANALYTICS_FAILURE, { filters, error }),
};

export const loadAnalyticsPage = (filters) => ({
  type: actions.LOAD_ANALYTICS_PAGE,
  filters
});

import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faBell, faTh, faDirections, faCar, faUsers, faMap, faChartBar, faHeadset,
  faChevronLeft, faDownload, faFilter, faSearch, faTimes, faSort, faPlusSquare, faMinusSquare, faPlus,
  faCheckCircle, faMinusCircle, faBars
 } from "@fortawesome/free-solid-svg-icons";

export default function() {
  library.add(
    faTrashAlt,
    faBell,
    faTh,
    faDirections,
    faCar,
    faUsers,
    faMap,
    faChartBar,
    faHeadset,
    faChevronLeft,
    faDownload,
    faFilter,
    faSearch,
    faTimes,
    faSort,
    faPlusSquare,
    faMinusSquare,
    faPlus,
    faCheckCircle,
    faMinusCircle,
    faBars,
  );
}

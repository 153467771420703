//import * as actions from "../global/actions";

const initState = {
  filters: {
    limit: 20,
    page: 1,
    search: "",
    orderBy: "createdAt",
  },
};

export default function(state = initState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

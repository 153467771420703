import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import queryString from "query-string";

import { connect } from "react-redux";
import ResetPasswordForm from "../components/Forms/ResetPasswordForm";
import styled from "styled-components";

const Body = styled.div`
`;

class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      isRedirect: false,
    };
  }

  componentDidMount() {
    const { location: { search } } = this.props;
    const params = queryString.parse(search);
    this.setState({ token: params.token });
  }

  handleResetPassword = () => {
    this.setState({isRedirect: true});
  }

  render() {
    const { isRedirect } = this.state;
    if (isRedirect) {
      return (
        <Redirect to="/login"/>
      );
    }

    return (
      <React.Fragment>
        <nav className="tw-flex tw-justify-between tw-items-center tw-bg-white">
          <Link to="/" className="tw-pl-6 tw-my-2"><img src="/logo.png" width="134" alt="logo"/></Link>
        </nav>

        <Body className="tw-shadow-inner tw-py-4">
          <div className="tw-container tw-mx-auto">
            <Body>
              <div className="box tw-w-2/5 tw-mx-auto tw-mt-6">
                <div className="header">New Password</div>
                <ResetPasswordForm token={this.state.token} resetPasswordSuccess={this.handleResetPassword}/>
              </div>
            </Body>
          </div>
        </Body>
      </React.Fragment>
    );
  }
}

ResetPasswordPage.propTypes = {};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps, {
})(ResetPasswordPage);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router";

class PrivateRoute extends Component {
  render() {
    const {
      isAuthenticated,
      component: Comp,
      ...props
    } = this.props;

    return (
      <Route
        {...props}
        render={p =>
          isAuthenticated
            ? <Comp {...p} />
            : (
              <Redirect to={{
                pathname: "/login",
                state: { from: p.location },
              }} />
            )
        }
      />
    );
  }
}

function mapStateToProps(state) {
  const { app: {isAuthenticated} } = state;
  return { isAuthenticated };
}

export default connect(mapStateToProps, {})(PrivateRoute);

import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Navbar from "../components/Navbar";

const Body = styled.div`
`;

class Layout extends Component {
  render() {
    return (
      <React.Fragment>
        <Navbar/>
        <Body className="tw-shadow-inner tw-py-4">
          <div className="tw-container tw-mx-auto">
            {this.props.children}
          </div>
        </Body>
      </React.Fragment>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
  ]),
};

export default Layout;

import * as actions from "../global/actions";

const initState = {
  requests: [],
  pages: 0,
  filters: {
    limit: 20,
    page: 1,
    search: "",
    orderBy: "createdAt",
  },
};

export default function(state = initState, action = {}) {
  switch (action.type) {
    case actions.FETCH_REQUESTS_SUCCESS:
      return {
        ...state,
        requests: action.response || [],
        filters: {
          ...state.filters,
        }
      };

    case actions.FETCH_REQUESTS_FAILURE:
      return {
        ...state,
        requests: [],
      };

    default:
      return state;
  }
}

//import { normalize } from "normalizr";
import queryString from "query-string";
import appConfig from "../config/application";

const backendOptions = () => {
  return {
    headers: Object.assign(
      {
        "Content-Type": "application/json",
        access_token: localStorage.token
      },
    )
  };
};

export const callApiEndpoint = (endpoint, options) => {
  let status;
  return fetch(endpoint, options)
    .then(res => {
      status = res.status;
      return res.json();
    })
    .then(
      (result) => {
        if (status !== 200) {
          return {error: result.message};
        }
        return {response: result};
      },
      (error) => {
        console.log("Looks like there was a problem: \n", error);
      }
    );
};

// User Login
export const userLogin = (filters) => {
  const endpoint = `${appConfig.apiBackendURI}/auth/sign_in`;
  const options = {
    headers: Object.assign(
      {
        "Content-Type": "application/json",
      },
    ),
    method: "POST",
    body: JSON.stringify(filters),
  }

  return callApiEndpoint(endpoint, options);
};

export const userResetPassword = (values) => {
  const endpoint = `${appConfig.apiBackendURI}/auth/reset_password`;
  const options = {
    headers: Object.assign(
      {
        "Content-Type": "application/json",
      },
    ),
    method: "POST",
    body: JSON.stringify(values),
  }

  return callApiEndpoint(endpoint, options);
};

export const fetchUsers = (filters) => {
  const query = queryString.stringify({page: filters.page, limit: filters.limit});
  const endpoint = `${appConfig.apiBackendURI}/users?${query}`;
  return callApiEndpoint(endpoint, backendOptions());
};

export const createUser = (params) => {
  const options = {
    ...backendOptions(),
    method: "POST",
    body: JSON.stringify(params),
  }
  return callApiEndpoint(`${appConfig.apiBackendURI}/users`, options);
};

export const updateUser = (id, params) => {
  const options = {
    ...backendOptions(),
    method: "POST",
    body: JSON.stringify(params),
  }
  return callApiEndpoint(`${appConfig.apiBackendURI}/users/${id}`, options);
};

export const deleteUser = (id) => {
  const options = {
    ...backendOptions(),
    method: "DELETE"
  }
  return callApiEndpoint(`${appConfig.apiBackendURI}/users/${id}`, options);
};

export const fetchAnalytics = (filters) => {
  const endpoint = `${appConfig.apiBackendURI}/analytics`;
  return callApiEndpoint(endpoint, backendOptions());
};

export const fetchRequests = (filters) => {
  const query = queryString.stringify(filters);
  const endpoint = `${appConfig.apiBackendURI}/requests?${query}`;
  return callApiEndpoint(endpoint, backendOptions());
};

export const fetchRequest = (id) => {
  const endpoint = `${appConfig.apiBackendURI}/requests/${id}`;
  return callApiEndpoint(endpoint, backendOptions());
};

export const deleteRequest = (id) => {
  const options = {
    ...backendOptions(),
    method: "DELETE"
  }
  return callApiEndpoint(`${appConfig.apiBackendURI}/requests/${id}`, options);
};

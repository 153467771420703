import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadAnalyticsPage } from "../actions/analytics";

import _ from "lodash";
import moment from "moment";
import styled from "styled-components";
import Layout from "../components/Layout";

const Body = styled.div`
`;

const LineChart = require("react-chartjs").Line;

class DashboardPage extends Component {
  componentDidMount() {
    this.props.loadAnalyticsPage();
  }

  render() {
    const { data } = this.props;
    const chartData = {
    	labels: data.map(obj => obj.label),
    	datasets: [
    		{
    			label: "My First dataset",
    			fillColor: "rgba(220,220,220,0.2)",
    			strokeColor: "rgba(220,220,220,1)",
    			pointColor: "rgba(220,220,220,1)",
    			pointStrokeColor: "#fff",
    			pointHighlightFill: "#fff",
    			pointHighlightStroke: "rgba(220,220,220,1)",
    			data: data.map(obj => obj.total)
    		}
    	]
    };

    return (
      <Layout>
        <Body>
          <div className="tw-flex tw-justify-between tw-mb-4">
            <div className="tw-text-xl tw-font-medium">Dashboard</div>
          </div>

          <div className="tw-rounded tw-bg-white tw-shadow tw-mb-4">
            <div className="tw-p-4">
              <LineChart data={chartData} options={{ responsive: true }} height="100"/>
            </div>
          </div>
        </Body>
      </Layout>
    );
  }
}

DashboardPage.propTypes = {
  analytics: PropTypes.object.isRequired,
  loadAnalyticsPage: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { analytics } = state;

  const data = [];
  analytics.analytics.forEach((item) => {
    const label = moment(item.createdAt).format("DD/MM/YYYY");
    let index = _.findIndex(data, { 'label': label });
    if (index >= 0) {
      data[index] = {label, total: item.total + data[index].total};
    } else {
      data.push({label, total: item.total});
    }
  });

  return { analytics, data };
}

export default connect(mapStateToProps, {
  loadAnalyticsPage,
})(DashboardPage);

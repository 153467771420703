import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";

import { connect } from "react-redux";
import { loadUserLogin } from "../actions/app";
import styled from "styled-components";

const Body = styled.div`
`;

class LoginPage extends Component {
  constructor(props) {
    super(props);

    const domain = window.location.hostname;
    const isProduct = domain.includes('afi');
    this.state = {
      //email: "admin@example.com",
      //password: "123123aA1#",
      email: "",
      password: "",
      demo: !isProduct
    };
  }

  userLogin = () => {
    const { email, password } = this.state;
    this.props.loadUserLogin({email, password});
  }

  handleChangeEmail = (event) => {
    this.setState({ email: event.target.value });
  };

  handleChangePassword = (event) => {
    this.setState({ password: event.target.value });
  };

  componentDidMount() {
  }

  render() {
    const { app } = this.props;
    const { demo } = this.state;

    if (app.isAuthenticated) {
      if (app.user.role === 'user') {
        return (
          <Redirect to="/requests"/>
        );
      }

      return (
        <Redirect to="/"/>
      );
    }

    return (
      <React.Fragment>
        <nav className="tw-flex tw-justify-between tw-items-center tw-bg-primary">
          <Link to="/" className="tw-pl-6 tw-my-4">
            <img src="/logo.png" width="50" alt="logo"/>
          </Link>
        </nav>

        <Body className="tw-shadow-inner tw-py-4">
          <div className="tw-container tw-mx-auto">
            <Body>
              <div className="box tw-w-2/5 tw-mx-auto tw-mt-6">
                <div className="header">Login</div>
                <div className="tw-text-xs tw-text-red tw-mb-2">{this.props.app.message}</div>
                <div className="form-input-group">
                  <label className="label">Email Address</label>
                  <input className="form-control input" onChange={this.handleChangeEmail} value={this.state.email}/>
                </div>
                <div className="form-input-group">
                  <label className="label label-black">Password</label>
                  <input className="form-control input" type="password" onChange={this.handleChangePassword} value={this.state.password}/>
                </div>
                <div className="tw-flex tw-items-center tw-justify-between tw-mb-2">
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="remember-me" />
                    <label className="form-check-label tw-text-xs" htmlFor="remember-me">Remember Me</label>
                  </div>
                </div>
                <button className="btn btn-block btn-red"
                  onClick={this.userLogin}>LOGIN</button>
              </div>
            </Body>
          </div>
        </Body>
      </React.Fragment>
    );
  }
}

LoginPage.propTypes = {
  app: PropTypes.object.isRequired,
  loadUserLogin: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { app } = state;
  return { app };
}

export default connect(mapStateToProps, {
  loadUserLogin,
})(LoginPage);

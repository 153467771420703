import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class SearchInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value || "",
    };
  }

  handleChange = (e) => {
    this.props.onChange(e.target.value);
  }

  handleKeyPress = (e) => {
    if(e.keyCode === 13 && "onSubmit" in this.props){
      this.props.onSubmit();
    }
  }

  render() {
    return (
      <div className="tw-flex tw-flex-1 tw-flex-row tw-mr-4">
        <span className="tw-flex tw-items-center tw-rounded tw-rounded-r-none tw-px-3 tw-text-grey-light tw-border tw-border-solid tw-border-grey-light tw-border-r-0">
          <FontAwesomeIcon icon="search"/>
        </span>
        <input
          {...this.props}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyPress}
          type="text"
          className="tw-flex-1 tw-text-xs tw-text-black tw-py tw-pr-3 tw-rounded tw-rounded-l-none tw-border tw-border-solid tw-border-grey-light tw-border-l-0 tw-w-64 focus:tw-outline-none"
          />
      </div>
    );
  }
}

export default SearchInput;

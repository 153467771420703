import { all, fork } from "redux-saga/effects";

import app from "./app";
import analytics from "./analytics";
import requests from "./requests";

export default function* root() {
  yield all([
    fork(app),
    fork(analytics),
    fork(requests),
  ]);
}

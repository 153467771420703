// App
export const APP_LOAD_USER_LOGIN = "APP_LOAD_USER_LOGIN";
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";

export const APP_INIT_CHECKBOXS = "APP_INIT_CHECKBOXS";
export const APP_CHECK_CHECKBOXS = "APP_CHECK_CHECKBOXS";
export const APP_TOGGLE_CHECKBOXS = "APP_TOGGLE_CHECKBOXS";

// Users
export const LOAD_USERS_PAGE = "LOAD_USERS_PAGE";

// Analytics
export const LOAD_ANALYTICS_PAGE = "LOAD_ANALYTICS_PAGE";
export const FETCH_ANALYTICS_REQUEST = "FETCH_ANALYTICS_REQUEST";
export const FETCH_ANALYTICS_SUCCESS = "FETCH_ANALYTICS_SUCCESS";
export const FETCH_ANALYTICS_FAILURE = "FETCH_ANALYTICS_FAILURE";

// Requests
export const LOAD_REQUESTS_PAGE = "LOAD_REQUESTS_PAGE";
export const FETCH_REQUESTS_REQUEST = "FETCH_REQUESTS_REQUEST";
export const FETCH_REQUESTS_SUCCESS = "FETCH_REQUESTS_SUCCESS";
export const FETCH_REQUESTS_FAILURE = "FETCH_REQUESTS_FAILURE";

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import styled from "styled-components";
import Layout from "../components/Layout";

const Body = styled.div`
`;

class ProfilePage extends Component {
  render() {
    const { user } = this.props.app;
    return (
      <Layout>
        <Body>
          <div className="tw-flex tw-justify-between tw-mb-4">
            <div className="tw-text-xl tw-font-medium">Profile</div>
          </div>

          <div className="tw-text-xs tw-text-grey-grayish tw-rounded tw-bg-white tw-shadow tw-mb-4 tw-p-8">
            <div className="tw-flex tw-flex-wrap tw-mb-6">
              <div className="tw-flex-1">
                <div className="tw-font-semibold tw-mb-1">Full Name</div>
                <span className="tw-text-sm">{user.name}</span>
              </div>
              <div className="tw-flex-1">
                <div className="tw-font-semibold tw-mb-2">User Role</div>
                <span className="tw-capitalize tw-rounded-full tw-border-red tw-border tw-border-solid tw-bg-red-light tw-text-red tw-px-3 tw-py-1">
                  {user.role === "super" ? "Super Admin" : user.role}
                </span>
              </div>
              <div className="tw-flex-1">
                <div className="tw-font-semibold tw-mb-1">Email</div>
                <span className="tw-text-sm">{user.email}</span>
              </div>
            </div>
            <div className="tw-flex tw-flex-wrap tw-mb-6">
              <div className="tw-flex-1">
                <div className="tw-font-semibold tw-mb-1">Token</div>
                <span className="tw-text-sm">{user.tokenApi}</span>
              </div>
            </div>
          </div>
        </Body>
      </Layout>
    );
  }
}

ProfilePage.propTypes = {
  app: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  const { app } = state;
  return { app };
}

export default connect(mapStateToProps, {})(ProfilePage);

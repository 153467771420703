import { take, put, call, fork } from "redux-saga/effects";
import * as actionType from "../global/actions";
import * as action from "../actions/requests";
import * as api from "../global/api";

/** *************************** Subroutines ************************************/
function* fetchRequests(filters) {;
  yield put(action.fetchRequests.request(filters));

  const { response, error } = yield call(api.fetchRequests, filters);
  if (response) {
    yield put(action.fetchRequests.success(filters, response));
  } else {
    yield put(action.fetchRequests.failure(filters, error));
  }
}

/** ****************************************************************************/
/** ***************************** WATCHERS *************************************/
/** ****************************************************************************/
export default function* watchJob() {
  while (true) {
    const { filters } = yield take(actionType.LOAD_REQUESTS_PAGE);
    yield fork(fetchRequests, filters);
  }
}
